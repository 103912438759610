<template>
  <div :style="[layoutStyle, bgStyle]" :class="[layoutClass, bgClass]">
    <live-news-element
      v-for="(n, index) in content"
      v-bind:key="n.id"
      :content="n"
      :layout="elementLayout[index]"
      :style="elementStyle[index]"
      :skeleton="layout.skeleton"
      :showIconSource="false"
      dateFormat="LL"
    ></live-news-element>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";
import cloneDeep from "lodash/cloneDeep";
import isPlainObject from "lodash/isPlainObject";

export default {
  name: "LiveNewsGrid",
  mixins: [layoutMixin],
  components: {
    LiveNewsElement: () => import("@/components/livenews/LiveNewsElement"),
  },
  props: {
    content: [Array],
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    //
  },
  computed: {
    elementLayout: function() {
      var layouts = [];
      var cols = this.layout.columns;
      var rows = this.layout.rows;

      var w = 100 / cols;
      var h = 100 / rows;
      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          var itemLayout = cloneDeep(this.layout.item);

          Object.keys(itemLayout).forEach((l) => {
            if (isPlainObject(itemLayout[l])) {
              itemLayout[l].zoneWidth = (this.layout.zoneWidth * w) / 100;
              itemLayout[l].zoneHeight = (this.layout.zoneHeight * h) / 100;
            }
          });

          layouts.push(itemLayout);
        }
      }

      return layouts;
    },

    elementStyle: function() {
      var offset = 0.5;
      var cols = this.layout.columns;
      var rows = this.layout.rows;
      var pos = [];
      var gridX = 0;
      var gridY = 0;
      var w = 100 / cols;
      var h = (100 - (rows - 1) * offset) / rows;
      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          var x = gridX + j * w;
          var y = gridY + i * h + offset * i;
          var s = {
            position: "absolute",
            left: `${x}%`,
            top: `${y}%`,
            width: `${w}%`,
            height: `${h}%`,
          };

          if (this.layout.margin) {
            s["margin-top"] = this.layout.margin.top + "px";
            s["margin-right"] = this.layout.margin.right + "px";
            s["margin-bottom"] = this.layout.margin.bottom + "px";
            s["margin-left"] = this.layout.margin.left + "px";
          }

          pos.push(s);
        }
      }
      return pos;
    },
  },
  methods: {
    //
  },
};
</script>
